import React, { useState } from 'react'
import { withStyles, Tooltip, TextField, InputAdornment, Typography, Tabs, Tab, IconButton } from '@material-ui/core'
import { Add, Close, Search as SearchIcon, CloudUpload, SelectAll, Add as PopulateIcon } from '@material-ui/icons'

import Modal from '../../../common/Modal'
import InputGenerator from '../../../common/InputGenerator'
import GlobalProducts from '../../GlobalProducts/GlobalProducts'

import * as NOTIFICATIONS from '../../../utils/notification'
import * as PRODUCTS_REQUESTS from '../../../utils/requests/products'
import * as GLOBAL_PRODUCTS_REQUESTS from '../../../utils/requests/globalProducts'
import * as CATEGORIES_REQUESTS from '../../../utils/requests/categories'
import * as CONSTANTS from '../../../utils/constants'

import { i18n, getPrice } from '../../../utils/helpers'
import i18nLang from '../../../utils/i18n.json'
import ImageGalleryModal from './ImageGalleryModal'

let InputWrapper = props => <div>
    <InputGenerator
        key={props.key}
        fullWidth={true}
        InputLabelProps={props.shrink ? { shrink: true } : {}}
        margin="dense"
        {...props}
        value={props.value}
        {...props.input}
        onChange={props.onChange}
    />
</div>

let weightOptions = [
    { label: '', value: '' },
    { label: 'Grame', value: 'gr' },
    { label: 'Militri', value: 'ml' }
]

let quantityOptions = [
    { label: '', value: '' },
    { label: 'Bucati', value: 'piece' },
    { label: 'Persoane', value: 'person' },
    { label: 'Pahar', value: 'glass' },
    { label: 'Sticla', value: 'bottle' }
]

let CreateProduct = props => {
    const lang = localStorage.getItem('currentLanguage')

    let { classes } = props
    let translatableFields = [
        { value: '', type: 'text', key: 'name', name: 'name', label: 'Product Name *', inputProps: { style: { height: '21px' }, placeHolder: i18n(i18nLang['createProduct']['name']) } },
        { value: '', type: 'text', key: 'ingredients', name: 'ingredients', label: 'Ingredients', multiline: true, rows: 3, maxRows: 3, inputProps: { style: { height: 'auto' }, placeHolder: i18n(i18nLang['createProduct']['ingredients']) } },
        { value: '', type: 'text', key: 'description', name: 'description', label: 'Description', multiline: true, rows: 3, maxRows: 3, inputProps: { style: { height: 'auto' }, placeHolder: i18n(i18nLang['createProduct']['description']) } },
        { value: '', type: 'text', key: 'nutritionalValue', name: 'nutritionalValue', label: 'Nutritional Value', multiline: true, rows: 3, maxRows: 3, inputProps: { style: { height: 'auto' }, placeHolder: i18n(i18nLang['createProduct']['nutritionalValue']) } },
        { value: '', type: 'text', key: 'extraInfo', name: 'fieldName', label: 'Custom field name', inputProps: { placeHolder: i18n(i18nLang['createProduct']['customField']) } },
        { value: '', type: 'text', key: 'extraInfo', name: 'fieldValue', label: 'Custom field value', inputProps: { placeHolder: i18n(i18nLang['createProduct']['customFieldValue']) } }
    ]

    let initialFields = [
        { value: '', type: 'dropdownSelector', utils: 'Category *', name: 'category', options: [{ label: '', value: false }].concat([...props.categoriesAndSubcategories.categories].map(category => ({ label: i18n(category.name), value: category.id }))) },
        { value: '', type: 'dropdownSelector', utils: 'Subcategory *', name: 'subcategory', options: [{ label: '', value: false }].concat([...props.categoriesAndSubcategories.subcategories].map(subcategory => ({ label: i18n(subcategory.name), value: subcategory.id }))) },
        { value: '', type: 'dropdownSelector', utils: 'Weight Unit', name: 'weightUnit', options: weightOptions },
        { value: '', type: 'dropdownSelector', utils: 'Quantity Unit', name: 'quantityUnit', options: quantityOptions },
        // { value: '', type: 'dropdownSelector', utils: 'Type', name: 'type', options: [] },
        { value: '', type: 'number', name: 'price', label: 'Price *', inputProps: { style: { height: '21px' } } },
        { value: '', type: 'number', name: 'quantity', label: 'Quantity', inputProps: { style: { height: '20px' } } },
        { value: '', type: 'number', name: 'weight', label: 'Weight', inputProps: { style: { height: '20px' } } },
        { value: '', type: 'checkbox', name: 'isSpicy', label: 'Is spicy' },
        { value: '', type: 'number', name: 'waitingTime', label: 'Waiting Time' },
        { value: [], type: 'multiselector', name: 'allergens', utils: 'Allergens', options: CONSTANTS.ALLERGENS },
        { value: 'none', type: 'dropdownSelector', utils: 'Is Vegan', name: 'veganTag', options: [{ label: 'Not vegan', value: 'none' }, { label: 'Vegan', value: 'vegan' }, { label: 'Vegetarian', value: 'vegetarian' }, { label: 'Raw Vegan', value: 'rawVegan' }] },
        { value: '', type: 'number', name: 'calories', label: 'Calories' }
    ]

    const [productFields, setProductFields] = useState(initialFields)

    const [productPhoto, setProductPhoto] = useState(null)
    const [goesWellWith, setGoesWellWith] = useState(null)
    const [expand, setExpand] = useState(false)
    const [modalSize] = useState('lg')
    const [openGallery, setOpenGallery] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const [previewUrl, setPreviewUrl] = useState(null)
    const [showSpinner, setSpinner] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const [languageTabIndex, setLanguageTabIndex] = useState(0)
    const [openGlobalProducts, setOpenGlobalProducts] = useState(false)


    React.useEffect(() => {
        if (props.languages && productFields.length <= initialFields.length) {
            let finalFieldsToAppend = []

            props.languages.forEach(lang => {
                let short = Object.keys(lang)[0]
                let name = Object.values(lang)[0]
                translatableFields.forEach(field => {
                    finalFieldsToAppend.push({
                        ...field, name: `${field.name}-${short}`, label: `${field.label} - ${name.name}`
                    })
                })
            })
            setProductFields([...productFields, ...finalFieldsToAppend])
        }
    }, [])

    React.useEffect(() => {
        if (props.editing) {
            toggleEditModal()
        }
    }, [props.editing])

    const populateFieldsFromProduct = async rawProduct => {
        let newFields = [...productFields]
        let currentProduct = {}
        Object.keys(rawProduct).forEach(field => {
            if (typeof rawProduct[field] !== 'object' || field === 'goesWellWith' || field === 'allergens') {
                currentProduct[field] = rawProduct[field]
            }
            else {
                Object.keys(rawProduct[field]).forEach(fieldKey => {
                    currentProduct[`${field}-${fieldKey}`] = rawProduct[field][fieldKey]
                })
            }
        })

        let categoryId = null
        for (let key of Object.keys(currentProduct)) {
            const keyIndex = newFields.findIndex(field => field.name === key)

            if (keyIndex > -1) {

                if (key === 'category') categoryId = currentProduct[key]

                newFields[keyIndex].value = currentProduct[key]
            }
        }

        if (categoryId) {
            const subCategories = await CATEGORIES_REQUESTS.getSubcategories(props.restaurantId, props.menuId, categoryId)
            let subCategoryIndex = newFields.findIndex(field => field.name === 'subcategory')
            if (subCategoryIndex > -1) {
                newFields[subCategoryIndex].options = [{ name: '', label: '', value: false }].concat([...subCategories].map(category => ({ label: i18n(category.name), value: category.id })))
                if (newFields[subCategoryIndex].options.findIndex(subcategory => subcategory.value === currentProduct.subcategory) < 0) {
                    newFields[subCategoryIndex].error = true
                    NOTIFICATIONS.error("Select subcategory")
                }
            }
        }

        let allergensIndex = newFields.findIndex(field => field.name === 'allergens')
        if (allergensIndex > -1 && currentProduct.allergens) {
            let allergensValues = []
            currentProduct.allergens.forEach(alergen => {
                allergensValues.push(
                    CONSTANTS.ALLERGENS.find(alergenFound => alergenFound.value === alergen)
                )
            })
            newFields[allergensIndex].value = allergensValues
        }

        setImageUrl({ url: currentProduct.photoUrl, thumbUrl: currentProduct.photoThumbUrl })
        setPreviewUrl(currentProduct.photoUrl)
        setProductFields(newFields)
        setGoesWellWith(currentProduct.goesWellWith || [])
    }

    const toggleEditModal = () => {
        PRODUCTS_REQUESTS.getById(props.restaurantId, props.productToEdit).then(async rawProduct => {
            populateFieldsFromProduct(rawProduct)
        })
    }

    const addToGoesWell = product => {
        let goesWellWithCopy = goesWellWith && goesWellWith.length ? [...goesWellWith] : []
        if (!goesWellWithCopy.some(el => el.id === product.id)) {
            goesWellWithCopy = goesWellWithCopy.concat(product)
            setGoesWellWith(goesWellWithCopy)
        }
        else {
            NOTIFICATIONS.error("This product is already in the list")
        }
    }

    const removeFromGoesWell = product => {
        let goesWellWithCopy = goesWellWith && goesWellWith.length ? [...goesWellWith] : []
        goesWellWithCopy = goesWellWithCopy.filter(pr => i18n(pr.name).toLowerCase() !== i18n(product.name).toLowerCase())
        setGoesWellWith(goesWellWithCopy)
    }

    let onInputChange = async event => {
        let fieldIndex = productFields.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return
        let newFields = [...productFields]

        if (event.target.name === 'category') {
            let subcategories = await CATEGORIES_REQUESTS.getSubcategories(props.restaurantId, props.menuId, event.target.value)

            let subcatIndex = newFields.findIndex(field => field.name === 'subcategory')

            if (subcatIndex > -1) {
                newFields[subcatIndex].options = [{ label: '', name: '' }].concat(subcategories.map(subcategory => ({ label: i18n(subcategory.name), value: subcategory.id })))
            }

        }

        newFields[fieldIndex].value = event.target.value

        setProductFields(newFields)
    }

    const openGalleryHandler = () => {
        setOpenGallery(true)
    }

    const renderFields = () => {

        let jsonMap = {}
        productFields.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={classes.flexRow}>
                <div className={`${classes.flexColumn} ${classes.equalFlex}`}>
                    <div className={`${classes.flexColumn} ${classes.equalFlex}`}>
                        <div className={`${classes.flexRow} ${classes.equalFlex}`}>
                            <div className={`${classes.languageTab} ${classes.flexRow}`}>
                                {props.languages.map((lang, index) => {
                                    let langName = Object.keys(lang)[0]
                                    return <Typography
                                        key={index}
                                        component="div"
                                        role="tabpanel"
                                        hidden={languageTabIndex !== index}
                                        style={{ width: '100%' }}>
                                        {<div className={classes.flexColumn}>
                                            <div className={`${classes.paddingTopButtonLess}`}>
                                                <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap[`name-${langName}`]} key={`name-${langName}`} />
                                            </div>
                                            <div className={`${classes.paddingTopButtonLess}`}>
                                                <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap[`description-${langName}`]} key={`description-${langName}`} />
                                            </div>
                                            <div className={`${classes.paddingTopButtonLess}`}>
                                                <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap[`ingredients-${langName}`]} key={`ingredients-${langName}`} />
                                            </div>
                                            <div className={`${classes.paddingTopButtonLess}`}>
                                                <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap[`nutritionalValue-${langName}`]} key={`nutritionalValue-${langName}`} />
                                            </div>
                                        </div>}
                                        <div className={classes.flexRow}>
                                            <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                                                <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap[`fieldName-${langName}`]} key={`fieldName-${langName}`} />
                                            </div>
                                            <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                                                <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap[`fieldValue-${langName}`]} key={`fieldValue-${langName}`} />
                                            </div>
                                        </div>
                                    </Typography>
                                })}
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={languageTabIndex}
                                    onChange={(_, newIndex) => {
                                        setLanguageTabIndex(newIndex)
                                    }}
                                    style={{ minWidth: 160, borderLeft: '1px solid #e5e5e5' }}
                                    className={classes.tabs}
                                >
                                    {props.languages.map((lang, index) => {
                                        let langName = Object.values(lang)[0]
                                        return <Tab key={index} classes={{ root: classes.tabRoot }} label={`${langName.name}`} id={`vertical-tab-${index}`} aria-controls={`vertical-tabpanel-${index}`} />
                                    })}
                                </Tabs>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <div className={`${classes.flexRow}`}>
                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['price']} key={'price'} />
                                    </div>
                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['category']} key={'category'} />
                                    </div>
                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['subcategory']} key={'subcategory'} />
                                    </div>

                                    {/* <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                            <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['type']} key={'type'} />
                        </div> */}
                                </div>
                                <div className={classes.flexRow}>

                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['quantity']} key={'quantity'} />
                                    </div>
                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton} ${classes.leftCloser}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['quantityUnit']} key={'quantityUnit'} />
                                    </div>
                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['weight']} key={'weight'} />
                                    </div>
                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton} ${classes.leftCloser}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['weightUnit']} key={'weightUnit'} />
                                    </div>

                                </div>
                                <div className={classes.flexRow}>
                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['waitingTime']} key={'waitingTime'} />
                                    </div>
                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['calories']} key={'calories'} />
                                    </div>
                                </div>
                                <div className={classes.flexRow}>
                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['veganTag']} key={'veganTag'} />
                                    </div>
                                    <div className={`${classes.equalFlex} ${classes.paddingTopButton}`} style={{ marginTop: '10px' }}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['allergens']} key={'allergens'} />
                                    </div>
                                    <div style={{ width: '100px', marginTop: '4px' }} className={`${classes.paddingTopButton}`}>
                                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['isSpicy']} key={'isSpicy'} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Typography className={classes.goesWellWithTitle} component='h2'>{props.currentMenu && props.currentMenu.goesWellWith && props.currentMenu.goesWellWith[lang] && props.currentMenu.goesWellWith[lang].length ? props.currentMenu.goesWellWith[lang] : i18nLang.goesWellWith[lang]}:</Typography>
                        <div className={`${classes.equalFlex} ${classes.paddingTopButton}`}>
                            <div className={classes.goesWellWithContainer}>
                                <div onClick={() => {
                                    if (!expand) {
                                        props.getRestaurantProducts()
                                        return setExpand(true)
                                    }
                                    return setExpand(false)
                                }} className={classes.addContainer} style={!expand ? { width: 175 } : {}}>
                                    <div className={classes.circle}>
                                        {expand ? <div className={classes.flexRow}>
                                            <Close className={classes.addIcon} />
                                        </div> : <Add className={classes.addIcon} />}

                                    </div>
                                    <h6>{!expand ? 'ADD PRODUCT' : 'CLOSE'}</h6>
                                </div>
                                {goesWellWith && goesWellWith.length ? goesWellWith.map(product => {
                                    return (
                                        <Tooltip placement="top" title={"Click to remove"}>
                                            <div onClick={() => removeFromGoesWell(product)} className={`${classes.addContainer} ${classes.goesWithProduct}`} style={!expand ? { width: 175 } : {}} >
                                                <div className={classes.imgPart}>
                                                    <div className={classes.goesWellProductImageSelected} style={{ backgroundImage: `url(${product.photoThumbUrl})`, backgroundSize: 'cover' }} />
                                                </div>
                                                <div className={classes.categoryPart}>
                                                    <Typography style={{ color: '#000000' }} component='h4' className={classes.noMarginPadding}>{i18n(product.name).length > 20 ? i18n(product.name).substr(0, 20) + '...' : i18n(product.name)}</Typography>
                                                    <span className={classes.tag}>Price: {getPrice(product.price, props.currencies, props.languages)}</span>
                                                </div>
                                            </div>
                                        </Tooltip>
                                    )
                                }) : null}
                            </div>
                        </div>
                    </div>

                </div>
                {expand ?
                    <div className={`${classes.flexColumn} ${classes.availableProducts}`}>
                        <Tooltip title={"Press ENTER to search"}>
                            <TextField
                                className={classes.searchInput}
                                value={searchInput}
                                placeholder="Search product"
                                onKeyPress={event => event.key === 'Enter' ? props.onSearch(searchInput) : () => { }}
                                onChange={event => setSearchInput(event.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon className={classes.searchIcon} color="secondary" />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Tooltip>
                        <ul className={classes.goesWellWithUl}>
                            {props.availableProducts.map(product => {
                                return (
                                    <li onClick={() => addToGoesWell(product)} className={classes.productContainer}>
                                        <div className={classes.imageSection}>
                                            <div className={classes.goesWellProductImage} style={{ backgroundImage: `url(${product.photoThumbUrl})`, backgroundSize: 'cover' }} />
                                        </div>
                                        <div className={classes.detailsSection}>
                                            <div style={{ flex: 1 }}>
                                                <Typography style={{ marginTop: '-3px' }} component="h3" className={classes.noMarginPadding}>{i18n(product.name).length > 25 ? i18n(product.name).substr(0, 25) + '...' : i18n(product.name)}</Typography>
                                            </div>
                                            <Typography component="span" className={classes.tag}>Price: {getPrice(product.price, props.currencies, props.languages)}</Typography>
                                        </div>

                                    </li>
                                )
                            })}
                        </ul>
                    </div> : null}
            </div>
        )
    }

    const resetForm = () => {
        setPreviewUrl(null)
        setImageUrl(null)
        setProductFields(productFields.map(field => {
            let value = ''
            switch (field.value) {
                case 'allergens':
                    value = []
                    break
                case 'veganTag':
                    value = 'none'
                    break
                default:
                    value = ''
            }
            return { ...field, value }
        }))
    }


    const onCreate = () => {
        // if (!productPhoto && !imageUrl) return NOTIFICATIONS.error("Please upload a photo")
        let productJson = {}

        let allGood = true
        let customFieldAllGood = true
        let nrInputs = 0

        let productFieldsCopy = [...productFields].map(obj => ({ ...obj }))
        props.languages.map((lang) => {
            productFieldsCopy.forEach((field) => {
                if (field.key === 'name' || field.name === 'price' || field.name === 'category' || field.name === 'subcategory') {
                    if (!field.value.toString().trim().length) {
                        field.error = true
                        allGood = false
                    } else {
                        field.error = false
                    }
                }
                // if(field.name === `fieldName-${Object.keys(lang)[0]}` || field.name === `fieldValue-${Object.keys(lang)[0]}`) {
                //     if(!field.value.toString().trim().length) {
                //         field.error = true
                //         customFieldAllGood = false
                //         nrInputs ++
                //     }
                //     else {
                //         field.error = false
                //     } 
                // }
                if (nrInputs === Object.keys(props.languages).length * 2) {
                    customFieldAllGood = true
                }
            })
        })

        if (!allGood || !customFieldAllGood) {
            setProductFields(productFieldsCopy)
            return NOTIFICATIONS.error("Please complete required forms")
        }

        setSpinner(true)

        let productsWithKey = productFields.filter(field => field.key)
        let productsWithoutKeys = productFields.filter(field => !field.key)

        let values = {}
        productsWithKey.forEach(field => {
            let name = field.name.split('-')[0]
            let lang = field.name.split('-')[1]
            if (!values[name]) {
                values[name] = {
                    [lang]: field.value
                }
            } else values[name][lang] = field.value
        })

        let totalProducts = [...productsWithoutKeys, ...translatableFields.map(field => ({ ...field, value: JSON.stringify(values[field.name]) }))]

        totalProducts.forEach(field => {
            productJson[field.name] = field.value
        })

        // if(!productJson['category']) return NOTIFICATIONS.error("Please select a category")
        // if(!productJson['subcategory']) return NOTIFICATIONS.error("Please select a subcategory")

        productJson.allergens = productJson.allergens ? productJson.allergens.map(allergen => allergen.value) : []


        let productImage = Array.from(productPhoto || [])
        let productFormData = new FormData()
        productFormData.append('image', productImage[0])
        Object.keys(productJson).forEach(key => {
            productFormData.append(key, productJson[key])
        })

        if (imageUrl && imageUrl.url && imageUrl.url === previewUrl) {
            productFormData.append('photoUrl', imageUrl.url)
            productFormData.append('photoThumbUrl', imageUrl.thumbUrl)
        }

        if (goesWellWith && goesWellWith.length) {
            productFormData.append('goesWellWith', Array.from(goesWellWith.map(product => product.id)))
        }

        if (props.editing) {
            productFormData.append('menuId', props.menuId)
            PRODUCTS_REQUESTS.edit(props.restaurantId, props.productToEdit, productFormData).then(res => {
                props.getProducts()
                onClose()
                NOTIFICATIONS.success("Successfully edited new product")
                setSpinner(false)
            })
                .catch(err => {
                    if (err.response && err.response.status === 413) {
                        NOTIFICATIONS.error("Error, image size limit is 1MB")
                    } else {
                        NOTIFICATIONS.error("Fail to edit")
                    }
                    setSpinner(false)
                })
        }
        else {
            productFormData.append('order', props.order)
            PRODUCTS_REQUESTS.create(props.restaurantId, props.menuId, productFormData).then(res => {
                props.getProducts()
                onClose()
                NOTIFICATIONS.success("Successfully added new product")
                setSpinner(false)
            })
                .catch(err => {
                    if (err.response && err.response.status === 413) {
                        NOTIFICATIONS.error("Error, image size limit is 1MB")
                    } else {
                        NOTIFICATIONS.error("Fail to create")
                    }
                    setSpinner(false)
                })
        }
    }

    const onClose = () => {
        props.onClose()
        setExpand(false)
        setGoesWellWith(null)
        resetForm()
    }

    const populateProduct = productId => {
        setOpenGlobalProducts(false)
        GLOBAL_PRODUCTS_REQUESTS.getById(productId).then(rawProduct => {
            populateFieldsFromProduct(rawProduct)
            NOTIFICATIONS.success("Product populated")
        }).catch(err => {
            NOTIFICATIONS.error("Cannot copy product")
        })
    }

    return (
        <Modal
            onCancel={onClose}
            onClose={onClose}
            acceptButtonText={props.editing ? "EDIT PRODUCT" : "ADD PRODUCT"}
            title={props.editing ? "EDIT PRODUCT" : <span>{'CREATE NEW PRODUCT'}  <IconButton style={{ marginLeft: 20 }} onClick={() => setOpenGlobalProducts(true)}><PopulateIcon /></IconButton></span>}
            cancelButtonText={"CANCEL"}
            maxWidth={modalSize}
            onAccept={() => onCreate()}
            spinner={showSpinner}
            open={props.open}>
            <div style={{ backgroundImage: `url(${previewUrl ? previewUrl : 'https://via.placeholder.com/800x150.png'})` }} className={classes.imagePreview}>
                <div className={`${classes.flexRow}`}>
                    <input onChange={event => {
                        setProductPhoto(event.target.files)
                        setPreviewUrl(URL.createObjectURL(event.target.files[0]))
                    }} type="file" name="file" accept="image/x-png,image/gif,image/jpeg" id="file" className={classes.inputfile} />
                    <Tooltip title='Upload photo'><label htmlFor="file" color='primary'><CloudUpload color='primary' className={classes.uploadButton} /></label></Tooltip>
                    <Tooltip title='Select photo from gallery'><SelectAll className={classes.uploadButton} onClick={() => openGalleryHandler()} /></Tooltip>
                </div>
            </div>
            {renderFields()}
            <ImageGalleryModal
                restaurantId={props.restaurantId}
                onClose={() => setOpenGallery(false)}
                selectImage={image => {
                    setImageUrl(image)
                    setPreviewUrl(image.url)
                    setOpenGallery(false)
                }}
                open={openGallery}
            />
            <Modal
                open={openGlobalProducts}
                onClose={() => setOpenGlobalProducts(false)}
                onCancel={() => setOpenGlobalProducts(false)}
                title={"Select product to copy"}
                cancelButtonText={"CLOSE"}
                maxWidth={'md'}
            >
                <GlobalProducts
                    disableDetails={true}
                    onClickRow={productId => populateProduct(productId)}
                />
            </Modal>
        </Modal>
    )
}

let styles = theme => ({
    leftCloser: {
        marginLeft: '-5px !important'
    },
    languageTab: {
        borderRight: '2px solid #e0e0e0',
        marginTop: '10px',
        width: '75%'
    },
    uploadButton: {
        padding: 10,
        transition: 'all .2s ease-in-out',
        cursor: 'pointer',
        '&:hover': {
            color: '#f50057 !important',
            transform: 'scale(1.2)'
        },
        '&:nth-of-type(2)': {
            paddingLeft: 0
        },
        '&:nth-of-type(1)': {
            marginLeft: 10
        }
    },
    goesWellWithTitle: {
        color: '#9ca4ab',
        marginLeft: '10px'
    },
    tag: {
        padding: '3px',
        paddingLeft: '0px',
        fontSize: '12px',
        alignSelf: 'flex-start'
    },
    goesWellWithUl: {
        paddingLeft: '0px',
        maxHeight: '340px',
        overflow: 'auto'
    },
    imagePreview: {
        height: '150px',
        width: '100%',
        backgroundSize: 'cover'
    },
    imgPart: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '100%'
    },
    categoryPart: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 'inherit',
        flex: 1,
        padding: 4
    },
    inputfile: {
        display: 'none'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    equalFlex: {
        flex: 1
    },
    paddingTopButton: {
        padding: '10px 0px',
        margin: '0px 10px'
    },
    paddingTopButtonLess: {
        padding: '5px',
        margin: '5px'
    },
    tabRoot: {
        minHeight: 30,
        fontSize: '13px !important'
    },
    goesWellWithContainer: {
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        backgroundColor: '#9ca4ab',
        borderRadius: 5,
        maxHeight: 310,

        overflow: 'auto'
    },
    addContainer: {
        height: 130,
        width: 205,
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: 5,
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#9ca4ab',
        backgroundColor: 'white',
        boxShadow: ' 7px 9px 16px 0px rgba(176,176,176,1)',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    imageSection: {
        paddingRight: 10
    },
    searchInput: {
        width: '100%',
        boxSizing: 'border-box',
    },
    searchIcon: {
        fontSize: '21px'
    },
    goesWellProductImage: {
        width: 54,
        height: 54,
        marginTop: -4,
        marginBottom: -4
    },
    goesWellProductImageSelected: {
        width: '100%',
        height: '100%'
    },
    detailsSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    priceSection: {
    },
    goesWithProduct: {
        alignItems: 'flex-start',
        width: 205
    },
    circle: {
        marginTop: 10,
        backgroundColor: '#9ca4ab',
        borderRadius: '50%',
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'black',
    },
    productContainer: {
        marginTop: '5px',
        cursor: 'pointer',
        padding: '4px 4px 4px 4px',
        borderBottom: '1px solid rgba(0,0,0,0.2)',
        fontFamily: 'serif',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        boxSizing: 'border-box',
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)'
        }
    },
    availableProducts: {
        width: 300,
        backgroundColor: '#f0f0f0',
        borderLeft: '2px solid #9ca4ab',
    },
    noMarginPadding: {
        margin: 0,
        padding: 0
    }
})

export default withStyles(styles)(CreateProduct)