import React, { useEffect, useState } from 'react'

import { withRouter, Link, useHistory } from 'react-router-dom'
import { withStyles, Button, Grid, Paper } from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'
import * as MENU_REQUESTS from '../../../utils/requests/menus'
import i18n from '../../../utils/i18n.json'
import { getLanguageMobile } from '../../../utils/helpers'
let menuId = null
let MenuType = props => {

    let lang = localStorage.getItem('languageMobile')
    let { classes } = props
    let [categories, setCategories] = useState(null)
    let [renderPage, setRenderPage] = useState(false)
    const history = useHistory();

    useEffect(() => {
        getMenus()
    }, [props.restaurant])

    let getMenus = () => {
        MENU_REQUESTS.getByRestaurantIdMobile(props.restaurant.id, true).then(res => {
            setCategories(res.categories)
            if (props.history.location.state.categoryId) {
                let subcategories = res.categories.map(category => category.subcategories)
                let newCategories = []
                props.history.location.state.categoryId
                    .forEach(categoryId => subcategories
                        .forEach(categs => newCategories
                            .push(categs.find(item => item.id === categoryId))))
                props.setSubcategories(newCategories.filter(subcategory => subcategory))
            }
            menuId = res.menuId
            setRenderPage(true)
        })
    }


    let renderMenus = () => {
        const selectedLanguage = getLanguageMobile(props.language)
        return (
            <>
                <div className={classes.minHeightBackButton}>
                    <Button className={props.theme === 'darkMode' ? classes.darkModeBackButton : classes.lightModeBackButton}
                        onClick={() => {
                            props.setSelectMenuType(false)
                            props.setDisplayPhoto(true)
                        }}>
                        <ArrowBackIos />
                        <span className={classes.fontSizeBackButton}>{i18n.menuBtn[lang]}  </span>
                    </Button>
                </div>
                <Grid container spacing={2} className={classes.root} justifyContent='center' >
                    {categories && categories.length && categories.map((category, index) => {
                        const categoryImage = category.imageUrlDark && category.imageUrlLight
                        let categoryName = category.name[selectedLanguage]
                        return (
                            <Grid key={`${category.id}_${index}`} item xs={12} sm={6} md={6} >
                                <div className={classes.linkStyle}
                                    onClick={() => {
                                        props.onCategoryClick(category)
                                        history.push({
                                            pathname: `/mobile/${props.restaurant.id}/menu/${menuId}/category/${category.id}`,
                                            search: window.location.search
                                        })
                                    }}>
                                    <Paper
                                        elevation={5}
                                        style={props.stylesDesign.secondary}
                                        className={`${classes.paper} ${classes.menuText}`}>
                                        {categoryImage ?
                                            <div className={classes.categoryImageContainer}>
                                                <img className={classes.categoryImage} src={props.theme === 'darkMode' ? category.imageUrlDark : category.imageUrlLight} />
                                            </div>
                                            : null}
                                        <div className={categoryImage ? classes.categoryNameContainer : classes.categoryNameContainerNoImage}>
                                            {categoryName}
                                        </div>
                                    </Paper>
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </>
        )
    }

    if (renderPage) {
        return (
            <>
                {renderMenus()}
            </>
        )
    }
    else return null
}


let styles = theme => ({
    root: {
        width: '95%',
    },
    paper: {
        padding: `1.2em 0 1.2em 0`,
        borderRadius: '0.5em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '2em'
    },
    categoryImageContainer: {
        flex: '0.2',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        left: '10px'
    },
    categoryNameContainer: {
        flex: '0.8',
        textAlign: 'center'
    },
    categoryNameContainerNoImage: {
        textAlign: 'center',
    },
    paper_darkMode: {
        padding: `1.2em 0 1.2em 0`,
        textAlign: 'center',
        borderRadius: '0.5em',
    },
    container: {
        width: '100%',
        display: 'flex',
        paddingTop: '30px',
        paddingBottom: '30px',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto'
    },
    menuNameContainer: {
        width: '100%',
        textAlign: 'center',
        padding: '18px 0px'
    },
    menuText: {
        fontSize: 18,
        fontWeight: 500,
        color: 'inherit',
        position: 'relative'
    },
    linkStyle: {
        textDecoration: 'none',
        color: 'inherit',
        width: '100%'
    },
    darkModeBackButton: {
        paddingTop: '1em',
        margin: '0 0 1em 0',
        color: 'white'
    },
    lightModeBackButton: {
        paddingTop: '1em',
        margin: '0 0 1em 0',
        color: 'black',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    fontSizeBackButton: {
        fontSize: '1.4em'
    },
    minHeightBackButton: {
        minHeight: '3em'
    },
    categoryImage: {
        maxHeight: '60px',
        maxWidth: '60px',
    }
})

export default withRouter(withStyles(styles)(MenuType))