import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Menus from '../../Menus/Menus'
import Tablets from '../../Tablets/Tablets'
import RestaurantSettings from '../RestaurantSettings/RestaurantSettings'
import RestaurantPhotos from '../RestaurantPhotos/RestaurantPhotos'
import FullPageLoading from '../../../common/FullPageLoading'
import RestaurantHeader from '../../../common/RestaurantHeader'
import * as RESTAURANT_REQUESTS from '../../../utils/requests/restaurants'
import RestaurantAnalytics from '../RestaurantAnalytics/RestaurantAnalytics'
import RestaurantDesign from '../RestaurantDesign/RestaurantDesign'


const useStyles = makeStyles({
    root: {
    },
    flexColumn: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    imageStyle: {
        maxHeight: '100%',
        width: '100%',
        objectFit: 'fill'
    },
    contentContainer: {
        paddingTop: 12,
        maxHeight: 'calc(100% - 306px)',
        width: '100%'
    }
})

let CenteredTabs = props => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.changeTab(newValue)
    }

    return (
        <Paper className={classes.root}>
            <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab value={0} label="Menus" />
                <Tab value={1} label="Tablets" />
                <Tab value={2} label="Upload files" />
                <Tab value={3} label="Design" />
                <Tab value={4} label="Settings" />
                {/* <Tab value={5} label="Analytics" /> */}
            </Tabs>
        </Paper>
    );
}

let RestaurantContent = props => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = React.useState(0)
    const [currentRestaurant, setRestaurant] = React.useState(null)

    const getCurrentRestaurant = async () => {

        let restaurant = await RESTAURANT_REQUESTS.getById(props.match.params.RESTAURANT_ID)

        setRestaurant({
            name: restaurant.name,
            baseCurrency: restaurant.baseCurrency,
            currencies: restaurant.currencies,
            photoUrl: restaurant.photoUrl,
            bookingId: restaurant.bookingId,
            bookingUrl: restaurant.bookingUrl,
            hideBookingButton: restaurant.hideBookingButton,
            id: restaurant.id,
            languages: restaurant.languages
        })
    }

    useEffect(() => {
        getCurrentRestaurant()
    }, [])

    const handleTabDisplay = () => {
        switch (selectedTab) {
            case 0:
                return <Menus baseCurrencySet={currentRestaurant.baseCurrency ? true : false} restaurant={currentRestaurant} restaurantId={props.match.params.RESTAURANT_ID} />
            case 1:
                return <Tablets restaurantId={props.match.params.RESTAURANT_ID} />
            case 2:
                return <RestaurantPhotos restaurantId={props.match.params.RESTAURANT_ID} />
            case 3:
                return <RestaurantDesign restaurantId={props.match.params.RESTAURANT_ID} />
            case 4:
                return <RestaurantSettings getCurrentRestaurant={() => getCurrentRestaurant()} restaurant={currentRestaurant} />
            // case 5:
            //     return <RestaurantAnalytics restaurantId={props.match.params.RESTAURANT_ID} />
            default:
                return <Menus restaurant={currentRestaurant} restaurantId={props.match.params.RESTAURANT_ID} />
        }
    }

    return (currentRestaurant ? <div className={classes.flexColumn}>
        <RestaurantHeader restaurant={currentRestaurant} />
        <CenteredTabs changeTab={newValue => setSelectedTab(newValue)} />
        <div className={classes.contentContainer}>
            {handleTabDisplay()}
        </div>
    </div > : <FullPageLoading />
    )
}

export default withRouter(RestaurantContent)