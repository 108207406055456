import React, { useState } from 'react'
import { withStyles, Typography, Button } from '@material-ui/core'

import InputGenerator from '../../common/InputGenerator'
import * as NOTIFICATION from '../../utils/notification'
import * as USER_REQUEST from '../../utils/requests/user'

let Login = props => {
    let { classes } = props
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')


    return (
            <div className={classes.container}>
                <div className={classes.loginContainer}>
                    <div className={classes.title}>
                        <Typography variant="h3">{`wikoti-menus`}</Typography>
                    </div>
                    <div className={classes.form}>
                        <div className={classes.formContainer}>
                            <Typography variant="h2" className={classes.loginHeader}>Login</Typography>
                            <InputGenerator
                                type='text'
                                name='email'
                                label='E-mail'
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                                className={classes.input}
                            />
                            <InputGenerator
                                type='password'
                                name='password'
                                label='Parola'
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                                className={classes.input}
                            />
                            <Button className={classes.button} variant='contained' color='primary'
                                onClick={() => {
                                    USER_REQUEST.login(email, password).then(async result => {
                                        localStorage.setItem('token', result.token)
                                        props.isAuth()
                                    }).catch(err => {
                                        NOTIFICATION.error('Datele sunt incorecte')
                                    })
                                }}
                            >Login</Button>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loginContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#303f9f',
        flexDirection: 'row'
    },
    title: {
        width: '30%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
    },
    form: {
        width: '50%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white'
    },
    formContainer: {
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        backgroundColor: 'white',
        flexDirection: 'column'
    },
    input: {
        flex: 1,
        width: '80%',
        margin: '40px',
        '&:nth-of-type(2)': {
            marginTop: 20
        },
        '&:nth-of-type(1)': {
            marginBottom: 0
        },
        '& input': {
            padding: '20px',
            width: '80%',
            fontSize: '15px'
        }
    },
    loginHeader: {
        color: '#303f9f',
        fontSize: '40px',
        fontWeight: 500,
        marginTop: 80
    },
    button: {
        margin: '40px auto 80px auto',
        width: '80%',
        padding: '10px',
        '& span': {
            fontSize: '18px !important'
        }
    }
})


export default withStyles(styles)(Login);