import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles, Button } from '@material-ui/core'

import FullPageLoading from '../../../common/FullPageLoading'
import InputGenerator from '../../../common/InputGenerator'
import QRCode from 'qrcode.react'

import * as Notification from '../../../utils/notification'
import * as RESTAURANT_REQUESTS from '../../../utils/requests/restaurants'

let InputWrapper = ({ onChange, input, shrink, keyProps }) => <div>
    <InputGenerator
        key={keyProps}
        fullWidth={false}
        InputLabelProps={shrink ? { shrink: true } : {}}
        margin="dense"
        value={input.value}
        {...input}
        onChange={onChange}
    />
</div>

let RestaurantCurrencies = props => {
    let { classes } = props
    let [done, setDone] = useState(false)
    let [renderLanguages, setRenderLanguages] = useState(false)
    let [languages, setLanguages] = useState([])
    let [baseCurrency, setBaseCurrency] = useState([
        { value: '', type: 'dropdownSelector', utils: 'Base Currency', name: 'baseCurrency', options: [] },
        { value: 1, disabled: true, type: 'number', name: 'baseCurrencyValue', label: 'Base Currency Value' },
    ])
    let [additionalInputs, setAdditionalInputs] = useState([
        { value: '', disabled: false, type: 'string', name: 'bookingId', label: 'Booking ID' },
        { value: '', disabled: false, type: 'string', name: 'bookingUrl', label: 'Booking Url' },
        { value: false, disabled: false, type: 'checkbox', name: 'hideBookingButton', label: 'Hide Booking Button' },
    ])

    React.useEffect(() => {
        setLanguageOptions()
    }, [])

    let setLanguageOptions = () => {
        let baseCurrencyCopy = [...baseCurrency].map(field => ({ ...field }))
        let mappedOptions = []
        Object.keys(props.restaurant.languages).forEach(key => {
            Object.keys(props.restaurant.languages[key]).forEach(language => {
                mappedOptions = mappedOptions.concat({ label: props.restaurant.languages[key][language].name, value: language })
            })
        })

        baseCurrencyCopy[0].options = [{ label: '', value: '' }].concat(mappedOptions)

        // When already have baseCurrency and we want to populate
        if (props.restaurant && props.restaurant.baseCurrency) {
            baseCurrencyCopy[0] = { ...baseCurrencyCopy[0], value: props.restaurant.baseCurrency }

            let restaurantLanguages = []
            Object.keys(props.restaurant.currencies).forEach(key => {
                const currentCurrency = props.restaurant.languages.filter(lang => Object.keys(lang)[0].toLowerCase() === key.toLowerCase())
                const currentCurrencyLabel = currentCurrency.length > 0 && currentCurrency[0][key].name
                if (key.toLowerCase() !== baseCurrency[0].value.toLowerCase()) {
                    restaurantLanguages = restaurantLanguages.concat({ type: 'number', value: props.restaurant.currencies[key], name: key, label: currentCurrencyLabel })
                }
            })

            setLanguages(restaurantLanguages)
            setRenderLanguages(true)
        }
        setBaseCurrency(baseCurrencyCopy)

        // Set additional options
        if (props.restaurant) {
            const additionalInputsCopy = additionalInputs.map(copy => ({ ...copy }))
            additionalInputsCopy.forEach(input => {
                input.value = props.restaurant[input.name];
            })
            setAdditionalInputs(additionalInputsCopy)
        }
        setDone(true)
    }

    let onInputChange = event => {
        let fieldIndex = baseCurrency.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...baseCurrency]

        newFields[fieldIndex].value = event.target.value

        setBaseCurrency(newFields)

        if (event.target.name === 'baseCurrency' && event.target.value === '') {
            setRenderLanguages(false)
        }
        else if (event.target.name === 'baseCurrency' && event.target.value !== '') {
            setRenderLanguages(true)
            let restaurantLanguages = []
            Object.keys(props.restaurant.languages).forEach(key => {
                Object.keys(props.restaurant.languages[key]).forEach(language => {
                    if (language.toLowerCase() !== baseCurrency[0].value.toLowerCase()) {
                        restaurantLanguages = restaurantLanguages.concat({ type: 'number', value: '', name: language, label: props.restaurant.languages[key][language].name })
                    }
                })
            })

            setLanguages(restaurantLanguages)
        }
    }

    let onAdditionalSettingsChange = event => {
        let fieldIndex = additionalInputs.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...additionalInputs]

        newFields[fieldIndex].value = event.target.value

        setAdditionalInputs(newFields)
    }

    let onLanguageInputChange = event => {
        let fieldIndex = languages.findIndex(f => f.name === event.target.name)

        if (!(fieldIndex > -1)) return

        let newFields = [...languages]

        newFields[fieldIndex].value = event.target.value

        setLanguages(newFields)
    }

    let renderBaseCurrency = () => {
        let jsonMap = {}
        baseCurrency.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={classes.padding}>
                <h4 className={classes.titles}>Select base currency</h4>
                <div className={classes.displayRow}>
                    <div className={classes.baseCurrencyName}>
                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['baseCurrency']} key={'baseCurrency'} />
                    </div>
                    <div className={classes.baseCurrencyValue}>
                        <InputWrapper onChange={event => onInputChange(event)} shrink={true} input={jsonMap['baseCurrencyValue']} key={'baseCurrencyValue'} />
                    </div>
                </div>
            </div>
        )
    }

    let renderAdditionalSettings = () => {
        let jsonMap = {}
        additionalInputs.forEach(field => {
            jsonMap[field.name] = field
        })

        return (
            <div className={classes.padding}>
                <h4 className={classes.titles}>Additional settings</h4>
                <div className={classes.displayColumn}>
                    <InputWrapper onChange={event => onAdditionalSettingsChange(event)} shrink={true} input={jsonMap['bookingId']} key={'bookingId'} />
                    <InputWrapper onChange={event => onAdditionalSettingsChange(event)} shrink={true} input={jsonMap['bookingUrl']} key={'bookingUrl'} />
                    <InputWrapper onChange={event => onAdditionalSettingsChange(event)} shrink={true} input={jsonMap['hideBookingButton']} key={'hideBookingButton'} />
                </div>
            </div>
        )
    }

    let qrRender = () => {
        return (<div className={classes.padding}>
            <h4 className={classes.titles}>QR code for this restaurant</h4>
            <QRCode size={256} renderAs="canvas" value={`${process.env.REACT_APP_DOMAIN}/mobile/${props.restaurant.id}?ref=qr`} />
        </div>)
    }

    let languagesRender = () => {
        return (
            <div className={classes.padding}>
                <h4 className={classes.titles}>Complete currencies exchange rate value</h4>
                {languages.map((lang, index) => {
                    if (lang.name.toLowerCase() !== baseCurrency[0].value.toLowerCase()) {
                        return (
                            <div key={index}>
                                <InputWrapper onChange={event => onLanguageInputChange(event)} shrink={true} input={{ ...lang, type: 'number' }} key={lang.name} />
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    let submitCurrencies = () => {
        let resultCurrencies = {
            [baseCurrency[0].value]: baseCurrency[1].value
        }

        let allGood = true
        languages.forEach(lang => {
            resultCurrencies[lang.name] = Number(lang.value)
            if (!lang.value) allGood = false
        })

        if (!baseCurrency[1].value || !allGood) {
            return Notification.error("Please complete all forms")
        }

        let additionalInputsJson = {}
        additionalInputs.map(field => {
            return additionalInputsJson[field.name] = field.value
        })

        RESTAURANT_REQUESTS.setCurrencies(props.restaurant.id, { baseCurrency: baseCurrency[0].value, currencies: resultCurrencies, ...additionalInputsJson })
            .then(() => {
                props.getCurrentRestaurant()
                Notification.success("Settings set successfully")
            }).catch(() => {
                Notification.success("Could not set settings")
            })
    }

    if (done) {
        return (
            <div className={classes.container}>
                <div className={classes.content}>
                    {qrRender()}
                    {renderBaseCurrency()}
                    {renderLanguages ? languagesRender() : null}
                    {renderAdditionalSettings()}
                    {renderLanguages ? <Button className={classes.submitButton} color="primary" variant='contained' onClick={() => submitCurrencies()}>Save</Button> : null}
                </div>
            </div>
        )
    } else return <FullPageLoading />
}

let styles = theme => ({
    padding: {
        padding: '0px 20px 20px 20px',
        background: '#ffffff'
    },
    container: {
        width: '100%',
        overflow: 'auto',
        marginBottom: '40px'
    },
    titles: {
        paddingLeft: 8
    },
    displayRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    displayColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    baseCurrencyName: {
        flex: 1,
        margin: '0px 8px 0px 0px'
    },
    baseCurrencyValue: {
        flex: 1,
        margin: '0px 8px',
        marginTop: 4
    },
    content: {
        padding: 8,
        borderRadius: 4,
        background: '#ffffff',
        paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column'
    },
    submitButton: {
        width: 110,
        marginTop: 'auto',
        marginLeft: '21px',
    }
})

export default withRouter(withStyles(styles)(RestaurantCurrencies))